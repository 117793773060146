/* eslint-disable @typescript-eslint/no-use-before-define */
import axios, { AxiosRequestConfig } from "axios"
import cookies from "js-cookie"
import { API_URL } from "../config"

interface RequestOptions extends AxiosRequestConfig {
  retrying?: boolean
  endpoint: string
}

export async function request<T>(options: RequestOptions): Promise<T> {
  const method = options.method || `get`
  const headers = options.headers || {}

  const csrfToken = cookies.get(`csrf-token`) || {}
  const { data } = await axios({
    url: `${API_URL}/${options.endpoint}`,
    method,
    headers: {
      "Content-Type": `application/json`,
      "X-CSRF-Token": csrfToken,
      "Referrer-Policy": `origin-when-cross-origin`,
    },
    params: options.params,
    withCredentials: true,
  })
  return data
}
